import './init';
import { Suspense, useState } from 'react';
import { Reset, Theme } from '@resultadosdigitais/tangram-components';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { TRPCClientError } from '@trpc/client';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { ChannelProvider } from './channel';
import { router } from './router';
import { TRPCProvider, trpcClient } from './trpc';
import { UIProvider } from './ui';

function isInternalServerError(error: unknown) {
  return (
    error instanceof TRPCClientError && (error.data?.httpStatus || 500) >= 500
  );
}

function retryOnInternalServerError(failureCount: number, error: unknown) {
  return isInternalServerError(error) ? failureCount < 5 : false;
}

function Widget() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache(),
        defaultOptions: {
          queries: {
            suspense: true,
            refetchOnWindowFocus: false,
            retry: retryOnInternalServerError,
            useErrorBoundary: isInternalServerError,
          },
          mutations: { retry: retryOnInternalServerError },
        },
      })
  );

  return (
    <ChannelProvider>
      <TRPCProvider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <UIProvider>
            <Suspense>
              <RouterProvider router={router} />
            </Suspense>
          </UIProvider>
        </QueryClientProvider>
      </TRPCProvider>
    </ChannelProvider>
  );
}

let container = null;

if (!container) {
  container = document.getElementById('root') as HTMLElement;
  ReactDOM.createRoot(container).render(<Widget />);
}
