import { createContext, useContext, useEffect, useState } from 'react';

import { useChannel } from './channel';

interface UI {
  open: boolean;
}

const UIContext = createContext<{
  ui: UI;
  setUI: (ui: UI) => void;
}>(undefined!);

export const useUI = () => {
  const context = useContext(UIContext);
  if (!context) throw new Error('useUI must be used inside an UIProvider');
  return context;
};

export const UIProvider = ({ children }: { children: React.ReactNode }) => {
  const { channel } = useChannel();
  const [ui, setUI] = useState<UI>({ open: false });

  useEffect(() => {
    channel?.emit('onOpenChanged', ui.open);
  }, [ui]);

  return (
    <UIContext.Provider value={{ ui, setUI }}>{children}</UIContext.Provider>
  );
};
