import { createBrowserRouter } from 'react-router-dom';

export const router = createBrowserRouter([
  {
    lazy: () => import('./settings'),
    children: [
      {
        path: '/ag/:workspaceId/:agentId',
        lazy: () => import('./widget'),
        children: [
          {
            index: true,
            lazy: () => import('./agent'),
          },
        ],
      },
      {
        path: '/:workspaceId/:agentId',
        lazy: () => import('./standalone'),
        children: [
          {
            index: true,
            lazy: () => import('./agent'),
          },
        ],
      },
    ],
  },
]);
