import { createContext, useContext, useEffect, useState } from 'react';
import { ChildHandshake, LocalHandle, WindowMessenger } from 'post-me';

export type WidgetMethods = {};

export type WidgetEvents = {
  onOpenChanged: boolean;
  onRedirect: string;
};

const methods = {};

const messenger = new WindowMessenger({
  localWindow: window,
  remoteWindow: window.parent,
  remoteOrigin: '*',
});

const ChannelContext = createContext<{
  channel: LocalHandle<WidgetMethods, WidgetEvents>;
}>(undefined!);

export const useChannel = () => {
  const context = useContext(ChannelContext);
  if (!context)
    throw new Error('useChannel must be used inside an ChannelProvider');
  return context;
};

export const ChannelProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [handle, setHandle] = useState<
    LocalHandle<WidgetMethods, WidgetEvents>
  >(undefined!);

  useEffect(() => {
    ChildHandshake(messenger, methods).then((connection) => {
      const handle = connection.localHandle();
      setHandle(handle);
    });
  }, []);

  return (
    <ChannelContext.Provider value={{ channel: handle }}>
      {children}
    </ChannelContext.Provider>
  );
};
